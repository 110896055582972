/* .sidebar {
    padding: 20px;
    height: 100vh;
} */

#mobile {
    display: none;
}
@media (max-width: 900px) {
    #pc{
        display: none;
    }
    #mobile {
        display: flex;
        padding: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80vh
    }
    #link {
        width: auto;
        border-radius: 20px;
        font-size: 10px;
        margin: 5px;
        /* box-shadow: 5px 3px 0px 0px rgb(27 16 197 / 20%), 6px 9px 12px 1px #81a9cf */
        /* box-shadow: 7px 7px 5px 0px rgb(27 16 197 / 20%), 6px 9px 20px 0 #3085d6 */
    }
    #img_sidebar{width: 50% !important;}
    #nav_mobile {
        padding: 0;
    }
    
}
